<template>
  <avue-radio v-model="language" :dic="dic" class="languageRadio" @change="handleSetLanguage"></avue-radio>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "top-lang",
  data() {
    return {
      dic: [{
        label: '中文',
        value: 'zh'
      }, {
        label: 'English',
        value: 'en'
      }],
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["language", "tag"])
  },
  props: [],
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit("SET_LANGUAGE", lang);
      let tag = this.tag;
      let title = this.$router.$avueRouter.generateTitle(
        tag.label,
        (tag.meta || {}).i18n
      );
      //根据当前的标签也获取label的值动态设置浏览器标题
      this.$router.$avueRouter.setTitle(title);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
