var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLogin
    ? _c("div", { staticStyle: { width: "100%", height: "100%" } }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tip-title" }, [
      _c("img", { attrs: { src: require("../../assets/images/fail.png") } }),
      _c("span", { staticStyle: { "margin-top": "20px" } }, [
        _vm._v("暂无权限查看该页面~"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }