var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("basic-container", [
    _c("iframe", {
      ref: "iframe",
      staticClass: "iframe",
      attrs: { id: "frame", src: _vm.src },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }