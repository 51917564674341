var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("avue-radio", {
    staticClass: "languageRadio",
    attrs: { dic: _vm.dic },
    on: { change: _vm.handleSetLanguage },
    model: {
      value: _vm.language,
      callback: function ($$v) {
        _vm.language = $$v
      },
      expression: "language",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }