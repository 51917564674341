<template>
 <div  v-if="!isLogin" style="width:100%;height:100%">
  <div class="tip-title">
    <img src="../../assets/images/fail.png" />
    <span style="margin-top:20px">暂无权限查看该页面~</span>
  </div>
 </div>
</template>
<script>
import {mapGetters} from "vuex";
import { setTheme } from "@/util/util";
import { iframeLogin,getIframeUser } from "@/api/user"
import {getRoutes} from "@/api/system/menu";
export default {
  data() {
    return {
      isLogin: true
    };
  },
  computed: {
    ...mapGetters(["tagWel"]),
  },
  watch: {},
  methods: {},
  created() {

  },
  mounted() {
    let that = this
    //获取主页面传参信息
    window.addEventListener('message', function (e) {
       if (e.data.type === 'sessionid') {
          let sessionid = e.data.msg
          let params = {
            code:'ZXTJQ',
            data:{
            sessionid:sessionid,
            syscode:process.env.NODE_ENV === 'production'?'f07b1fdc90432dcea7998b98aec6a560':'559066db8556b5c2b393910ee706ed55'
            }
          }
            getIframeUser(params).then(result => {
              if(result.data.code === '200') {
              iframeLogin(result.data.data.loginid).then(res => {
                  if(res.status === 200 && res.data) {
                    that.isLogin = true
                    //存储登陆信息
                    that.$store.dispatch("clickLogin", res.data).then(() => {
                      getRoutes().then(res => {
                      that.$store.commit('SET_MENU', res.data.data);
                      // 设置嵌套页面左侧菜单栏主题
                      that.$store.commit("SET_THEME_NAME", "dark");
                      setTheme("dark")
                      //设置导航模式为侧边导航
                      that.$store.commit("SET_LAYOUTTYPE","classic");
                      //嵌套其他页面时隐藏顶部导航
                      that.$store.commit("SET_ISIFRAME_IN",true)
                      //重置面包屑导航
                      that.$store.commit("SET_TAG_LIST",[that.tagWel])
                      // 业务中台页面集成sinoma
                      // 1、根据用户名获取sinoma登录信息
                      // 模拟登陆后页面跳转到sinoma首页
                      that.$router.push({path: that.tagWel.value});
                    })
                  })
                  } else {
                    that.isLogin = false
                  }
                }).catch(()=>{
                  that.isLogin = false
                })
              } else {
                that.isLogin = false
              }
            }).catch((err)=>{
              that.isLogin = false
            })
        }
    }, false)

  }
}
</script>
<style scoped>
.tip-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  color:#cccccc;
}
</style>
