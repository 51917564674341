var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "disFlex" }, [
        _c("span", { staticStyle: { "padding-left": "30px" } }, [
          _c("i", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.$t("userinfo.title1"))),
          ]),
          _vm._v(_vm._s(_vm.$t("userinfo.title2"))),
        ]),
        _c("i", {
          staticClass: "el-icon-circle-close",
          staticStyle: { "font-size": "30px", cursor: "pointer" },
          on: { click: _vm.close },
        }),
      ]),
      _c(
        "el-row",
        {
          staticStyle: { "padding-top": "20px", width: "35%", margin: "auto" },
        },
        [
          _c("avue-form", {
            ref: "password",
            attrs: { option: _vm.passwdOption },
            on: {
              submit: _vm.handleSubmit,
              "return-login": function ($event) {
                return _vm.returnLogin()
              },
            },
            model: {
              value: _vm.passwdForm,
              callback: function ($$v) {
                _vm.passwdForm = $$v
              },
              expression: "passwdForm",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }