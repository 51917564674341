var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100vh", overflow: "auto" } },
    [
      _c("div", { staticClass: "topImg" }),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c(
        "div",
        { staticClass: "appInformation" },
        [
          _c("div", { staticClass: "informationTitle" }, [_vm._v("历史版本")]),
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, size: "medium", stripe: true },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "version", label: "版本", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "content",
                    label: "更新内容",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "date", label: "时间", align: "center" },
                }),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "appInformation" },
        [
          _c("div", { staticClass: "informationTitle" }, [_vm._v("产品展示")]),
          [
            _c(
              "el-carousel",
              { attrs: { interval: 4000, type: "card", height: "900px" } },
              _vm._l(_vm.imageList, function (item, index) {
                return _c("el-carousel-item", { key: index }, [
                  _c("img", {
                    staticClass: "appShowImg",
                    attrs: { src: item.src },
                  }),
                ])
              }),
              1
            ),
          ],
        ],
        2
      ),
      _c("div", { staticClass: "bottomBox" }, [
        _c("div", { staticClass: "bottomContent" }, [
          _c("p", { staticStyle: { color: "#FFFFFF" } }, [_vm._v("SnowSoft")]),
          _c(
            "p",
            {
              on: {
                click: function ($event) {
                  _vm.aboutShow = true
                },
              },
            },
            [_c("a", [_vm._v("关于我们")])]
          ),
          _c(
            "p",
            {
              on: {
                click: function ($event) {
                  _vm.linkShow = true
                },
              },
            },
            [_c("a", [_vm._v("联系我们")])]
          ),
        ]),
        _vm._m(3),
        _vm._m(4),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "关于我们", visible: _vm.aboutShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.aboutShow = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "北海铁山港水泥开发有限公司办公室地址位于中国西南地区唯一的沿海开放城市北海，北海 公馆镇陈村（邮编：536110），公司成立以来发展迅速，业务不断发展壮大，我公司主要经营水泥，生产，销售，，我公司与多家北海公司建立了长期稳定的合作关系，重信用、守合同、保证产品质量，赢得了广大客户的信任，公司全力跟随客户需求，不断进行产品创新和服务改进。"
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "联系我们", visible: _vm.linkShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.linkShow = $event
            },
          },
        },
        [
          _c("p", [_vm._v("地址：北海 公馆镇陈村")]),
          _c("p", [_vm._v("电话：(0779)")]),
          _c("p", [_vm._v("联系人：未提供")]),
          _c("p", [_vm._v("邮编：536110")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "disflex" }, [
      _c("div", { staticClass: "topLeft" }, [
        _c("img", { attrs: { src: require("@/assets/images/topLeft.jpg") } }),
      ]),
      _c("div", { staticClass: "topRight" }, [
        _c("div", { staticClass: "appLogo" }, [
          _c("img", { attrs: { src: require("@/assets/images/appLogo.png") } }),
        ]),
        _c("div", { staticClass: "appTitle" }, [_vm._v("水泥智能工厂")]),
        _c("div", { staticClass: "appSuit" }, [
          _c("div", { staticClass: "suitBox" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/Android.png") },
            }),
            _c("div", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("适用于 Android 设备"),
            ]),
            _c("img", {
              staticClass: "downloadImg",
              attrs: { src: require("@/assets/download/Android.jpg") },
            }),
            _c("div", { staticStyle: { margin: "10px 0" } }, [
              _vm._v("Android APP下载"),
            ]),
            _c("a", { attrs: { href: "https://www.pgyer.com/BKHNu1" } }, [
              _vm._v("https://www.pgyer.com/BKHNu1"),
            ]),
          ]),
          _c("div", { staticClass: "suitBox" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/iphone.png") },
            }),
            _c("div", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("适用于 Iphone 设备"),
            ]),
            _c("img", {
              staticClass: "downloadImg",
              attrs: { src: require("@/assets/download/IOS.jpg") },
            }),
            _c("div", { staticStyle: { margin: "10px 0" } }, [
              _vm._v("Iphone APP下载"),
            ]),
            _c("a", { attrs: { href: "https://www.wkzx.store/vip/4XIs" } }, [
              _vm._v("https://www.wkzx.store/vip/4XIs"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "appInformation" }, [
      _c("div", { staticClass: "informationTitle" }, [_vm._v("应用介绍")]),
      _c("div", { staticClass: "informationContent" }, [
        _vm._v(
          "\n      应用于北海铁山港水泥开发有限公司移动办公使用，实现随时随地的查看数据，录入数据，监测厂内设备情况\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "appInformation" }, [
      _c("div", { staticClass: "informationTitle" }, [_vm._v("应用信息")]),
      _c("div", { staticClass: "informationContent" }, [
        _c("div", { staticClass: "version-info" }, [
          _c("div", [_vm._v("大小")]),
          _c("div", [_vm._v("36.3 MB")]),
        ]),
        _c("div", { staticClass: "version-info" }, [
          _c("div", [_vm._v("更新时间")]),
          _c("div", [_vm._v("2023-07-30")]),
        ]),
        _c("div", { staticClass: "version-info" }, [
          _c("div", [_vm._v("版本")]),
          _c("div", [_vm._v("2.7.3 (build 6)")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottomContent" }, [
      _c("p", { staticStyle: { color: "#FFFFFF" } }, [_vm._v("商务合作")]),
      _c("p", [_c("a", [_vm._v("业务合作")])]),
      _c("p", [_c("a", [_vm._v("赞助投资")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottomContent" }, [
      _c("p", { staticStyle: { color: "#FFFFFF" } }, [_vm._v("服务支持")]),
      _c("p", [_c("a", [_vm._v("用户协议")])]),
      _c("p", [_c("a", [_vm._v("隐私协议")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }